import type { NextPage } from 'next'
import { ContentfulService } from '../services/contentful';
import { CONTENT_TYPE_PAGE } from '../services/constants';

import { IFooterFields, IHeaderFields, IPageFields } from '../@types/generated/contentful'

import Block from '../components/block'
import Layout from '../components/layout';

type Props = {
  page: IPageFields,
  header: IHeaderFields,
  footer: IFooterFields
}

const Page: NextPage<Props> = ({page, header, footer}:Props) => {
	return (
		<Layout title={page.title} slug={page.slug} meta={page.meta?.fields} header={header} footer={footer}>
			{ page.content.map(block => <Block key={block.sys.id} block={block}/>) }
		</Layout>
	)
}

export async function getServerSideProps(context: any) {
	const contentfulService = new ContentfulService();
	const { slug } = context.query;

	try {
		const responses = await Promise.all([
			contentfulService.getEntryBySlug(slug, CONTENT_TYPE_PAGE),
			contentfulService.getHeader(),
			contentfulService.getFooter(),
		]);
		if (!responses || !responses[0]) throw new Error ("Page not found");

		return {
			props: {
				page: responses[0].fields,
				header: responses[1]?.fields,
				footer: responses[2]?.fields,
			}
		}
	} catch(e) {
		return {
			notFound: true
		}
	}
}

export default Page