import React, { Fragment, FunctionComponent } from 'react';
import Head from 'next/head';
import { concatenateStrings } from '../../helpers/stringManipulation';
import { IMetaFields } from '../../@types/generated/contentful';
import { defaultMetaTags } from '../../services/constants'

type Props = {
  title: string,
  slug: string,
  meta: IMetaFields | undefined
}

const Meta: FunctionComponent<Props> = ({ title, slug, meta }) => {
   const metaContent = (meta !== undefined) ? meta : defaultMetaTags;
   const canonicalSlug = (slug === "home") ? "" : slug;

  return (
    <Fragment>
      <Head>
        <title key="title">{title} | SponsorPulse</title>
        <meta name="description" key="description" content={metaContent.description}/>        
        <meta property="og:type" key="og_type" content={metaContent.pageType}/>
        <meta property="og:title" key="og_title" content={metaContent.title}/>
        <meta property="og:description" key="og_description" content={metaContent.description}/>
        <meta property="og:url" key="og_URL" content={`${process.env.NEXT_PUBLIC_CANONICAL_URL}${(meta?.pageType == 'article') ? '/insights/':'/'}${canonicalSlug}`}/>
        <meta property="og:image" key="og_image" content={metaContent.shareImage.fields.file.url}/>
        <meta property="og:site_name" key="og_site_name" content={process.env.NEXT_PUBLIC_SITE_NAME}/>
        <meta name="robots" content={`${concatenateStrings(metaContent.robotsIndex,metaContent.robotsFollow)}`}/>
        <link rel="canonical" key="canonical" href={`${process.env.NEXT_PUBLIC_CANONICAL_URL}${(meta?.pageType == 'article') ? '/insights/':'/'}${canonicalSlug}`}/>
      </Head>
    </Fragment>
  );
};

export default Meta;