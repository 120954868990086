import React, { FunctionComponent, useEffect, useState } from 'react';
import Link from 'next/link'
import { useRouter } from 'next/router';
import { IPageFields } from '../../@types/generated/contentful';

type Props = {
    content: IPageFields,
    callback: Function
}

const HeaderNavigationLink: FunctionComponent<Props> = ({content, callback}) => {
    const [active,setActive] = useState(false);
    const router = useRouter();

    useEffect(() => {
        if (router.asPath === `/${content.slug}`)
            setActive(true);
        else
            setActive(false);
    }, [content.slug,router.asPath]);

    return (
        <li>
            <Link href={`/${content.slug}`}>
                <a className={`block px-6 text-sp-purple lg:border-b-2 ${(active) ? 'lg:border-sp-primary':'lg:border-transparent' } lg:p-0 mb-6 lg:mb-0 lg:hover:border-sp-primary`} onClick={() => callback()}>
                    {content.title}
                </a>
            </Link>
        </li>
    )
}

export default HeaderNavigationLink;