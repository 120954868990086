import React, {FunctionComponent, Fragment, ReactNode, useEffect} from 'react';
import { IFooterFields, IHeaderFields, IMetaFields } from '../../@types/generated/contentful';

import Meta from '../meta';
import Header from '../header';
import Footer from '../footer';

type Props = {
    title: string,
    slug: string,
    meta: IMetaFields | undefined,
    header: IHeaderFields | undefined,
    footer: IFooterFields | undefined,
    children: ReactNode;
}
const Layout: FunctionComponent<Props> = ({title, slug, meta, header, footer, children}) => {
    
    return (
        <Fragment>
            <Meta title={title} slug={slug} meta={meta} />
            <div className='flex flex-col min-h-screen'>
                <Header content={header} />
                    <main className='flex flex-grow bg-white'>
                        <div className='w-full'>
                            {children}
                        </div>
                    </main>
                <Footer content={footer} />
            </div>
        </Fragment>
    );
};

export default Layout;