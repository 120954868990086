import React, { FunctionComponent, useEffect, useState } from 'react';
import Link from 'next/link'
import Image from 'next/image';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';

import { IHeaderFields, IPageFields } from '../../@types/generated/contentful';

import { Fade as Hamburger } from 'hamburger-react'
import HeaderNavigationLink from './headerNavigationLink';
import SearchPropertyBox from './searchPropertyBox';

import { menuCTAs, menuItems } from '../../services/constants';

const CallToAction = dynamic(() => import('../callToAction'), {
    ssr: false,
  })

type Props = {
    content: IHeaderFields | undefined
}

const Header: FunctionComponent<Props> = ({content}) => {
    const [openNav,setOpenNav] = useState(false);
    const router = useRouter();

    const closeMenu = () => {
        setOpenNav(false)
    }

    useEffect(() => {
        setOpenNav(false)
    }, [router.asPath]);

    const navigation = (content?.navigation.fields.links) ? content.navigation.fields.links.map((link) => {
        return <HeaderNavigationLink key={link.sys.id} content={link.fields} callback={closeMenu}/>
    }) : menuItems.map((link) => {
        return <HeaderNavigationLink key={Math.random()} content={link as IPageFields} callback={closeMenu}/>
    })
    const callToAction = (content?.callToAction) ? content.callToAction?.map((cta) => {
        return <CallToAction key={cta.sys.id} content={cta.fields} callback={closeMenu} header={true}/>
    }) : menuCTAs.map((cta) => {
        return <CallToAction key={Math.random()} content={cta} callback={closeMenu} header={true}/>
    })

    return (
        <div className='sticky top-0 z-50 w-full bg-white lg:px-0 lg:drop-shadow-lg'>
            <nav className="lg:container h-20 lg:h-24 m-0 lg:m-auto flex items-center justify-between flex-wrap">
                <div className="flex z-20 items-center flex-shrink-0 text-white m-4 lg:ml-0 lg:mr-0 lg:mt-4 lg:mb-4 lg:ml-0">
                    <Link href='/'>
                        <a onClick={() => closeMenu()} className='w-56 lg:w-40 xl:w-56'>
                            <Image
                                src='/sponsorpulse-logo-color.png'
                                alt='SponsorPulse'
                                width={220}
                                height={40}
                                layout='responsive'
                                unoptimized={true}
                            />
                        </a>
                    </Link>
                </div>
                <div className="block z-20 lg:hidden m-4">
                    <span className="sr-only">Open main menu</span>
                    <Hamburger color='#371A32' toggled={openNav} toggle={() => setOpenNav(!openNav)}/>
                </div>
                <div className={`absolute z-10 top-0 w-full h-20 lg:hidden bg-white ${(openNav)? "":"drop-shadow-lg"}`}>
                
                </div>
                <div className={`w-full bg-white z-15 lg:-translate-y-0 ${ (openNav) ? "transition lg:transition-none ease-in-out -translate-y-0": `hidden -translate-y-full`} lg:flex-grow lg:flex lg:items-center lg:w-auto drop-shadow-lg lg:drop-shadow-none`}>
                    <div className="text-lg lg:flex-grow">
                        <ul className="flex flex-col lg:items-center lg:flex-row lg:space-x-8 xl:space-x-12 lg:mt-0 lg:ml-10 xl:ml-20 lg:text-base lg:font-normal">
                            { navigation }
                            <li>
                                <SearchPropertyBox />
                            </li>
                        </ul>
                    </div>
                    <div className="mx-6 lg:mx-0">
                        <ul className="flex flex-col lg:flex-row lg:space-x-4 xl:space-x-12 lg:mt-0 text-lg lg:text-base lg:font-normal">
                            { callToAction }
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Header;