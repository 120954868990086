import React, { FunctionComponent, useState, useEffect } from 'react'
import Autosuggest, {
  RenderInputComponentProps,
  RenderSuggestionsContainerParams,
} from 'react-autosuggest'
import Image from 'next/image'
import Link from 'next/link'
import PropertyImage from '../propertyImage'
import { fetchProperties } from '../../services/api'

type Props = {}

const SearchPropertyBox: FunctionComponent<Props> = ({}) => {
  const [properties, setProperties] = useState<Object[]>([])
  const [suggestions, setSuggestions] = useState<Object[]>([])
  const [value, setValue] = useState('')

  useEffect(() => {
    loadProperties()
  }, [])

  const loadProperties = async () => {
    const properties = await fetchProperties()
    setProperties(properties)
  }

  const onChange = (event: any, { newValue = '' }) => {
    setValue(newValue)
  }

  const onSuggestionsFetchRequested = ({ value = '' }) => {
    setSuggestions(getSuggestions(value))
  }

  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  }

  const getSuggestions = (value: string) => {
    const inputValue = value.trim().toLowerCase()

    if (inputValue.length === 0) return []
    return properties.filter((property: any) =>
      property.name.toLowerCase().startsWith(inputValue)
    )
  }

  const getSuggestionValue = (suggestion: any) => suggestion.name

  const inputProps = {
    placeholder: 'Search a property',
    value,
    onChange,
  }

  const renderInputComponent = (inputProps: RenderInputComponentProps) => {
    return (
      <div className="flex w-full items-center rounded-md border border-slate-300 bg-white px-4 lg:w-56 xl:w-64">
        <input
          {...inputProps}
          className="mr-2 flex-1 border-transparent px-0 outline-transparent ring-transparent focus:border-transparent focus:outline-transparent focus:ring-transparent"
        />
        <Image src="/icons/search.png" alt="" width={20} height={20} />
      </div>
    )
  }

  const renderSuggestion = (suggestion: any) => {
    return (
      <div className="flex items-center border-b px-3 py-2 text-base">
        <PropertyImage
          propertyName={suggestion.name}
          propertyCode={suggestion.value}
          size={30}
        />
        <span className="body-extra-small mx-2 flex-1">{suggestion.name}</span>
        <Link href={`/properties/${suggestion.slug}`}>
          <a className="body-extra-small rounded border border-sp-primary px-2 py-1 text-sp-primary">
            View
          </a>
        </Link>
      </div>
    )
  }

  const renderSuggestionsContainer = (
    container: RenderSuggestionsContainerParams
  ) => {
    return (
      <div
        {...container?.containerProps}
        className="no-scrollbar absolute top-14 z-10 max-h-40 w-full overflow-y-scroll rounded-md bg-white shadow-md lg:w-40 xl:w-64"
      >
        {container?.children}
      </div>
    )
  }

  return (
    <div className="mx-6 my-4 lg:m-0">
      <Autosuggest
        id="search-property-input"
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderInputComponent={renderInputComponent}
        renderSuggestionsContainer={renderSuggestionsContainer}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
      />
    </div>
  )
}

export default SearchPropertyBox
